import { ApolloError, gql, useQuery } from "@apollo/client";
import React, { ReactNode, createContext, useContext, useMemo } from "react";

export interface User {
  id: string;
  databaseId: number;
  firstName: string;
  lastName: string;
  email: string;
  capabilities: string[];
}

interface AuthData {
  loggedIn: boolean;
  user?: User;
  isUnlicensedUser: boolean;
  isCustomerDefault: boolean;
  isCyprusMerchant: boolean;
  isBankUser: boolean;
  isOtherBankUser: boolean;
  isSyntechUser: boolean;
  isDefaultCustomer: boolean;
  isCloudTalkUser: boolean;
  loading: boolean;
  error?: ApolloError;
}

const DEFAULT_STATE: AuthData = {
  isUnlicensedUser: false,
  isCustomerDefault: false,
  isCyprusMerchant: false,
  isBankUser: false,
  isOtherBankUser: false,
  isSyntechUser: false,
  isDefaultCustomer: false,
  isCloudTalkUser: false,
  loggedIn: false,
  user: undefined,
  loading: true,
  error: undefined,
};

const AuthContext = createContext(DEFAULT_STATE);

export const GET_USER = gql`
  query getUser {
    viewer {
      id
      databaseId
      firstName
      lastName
      email
      capabilities
    }
  }
`;

export function AuthProvider({ children }: { children: ReactNode }) {
  const { data, loading, error } = useQuery(GET_USER);
  const value = useMemo(() => {
    const user = data?.viewer;

    const isDefaultCustomer =
      data?.viewer?.capabilities.includes("customer_default");
    const isUnlicensedUser = data?.viewer?.capabilities.includes(
      "unlicensed_customer"
    );
    const isBankUser = data?.viewer?.capabilities.includes("customer_bank");
    const isOtherBankUser =
      data?.viewer?.capabilities.includes("cutomer_bank_2");
    const isSyntechUser = data?.viewer?.capabilities.includes("syntech_bank");
    const isCyprusMerchant =
      data?.viewer?.capabilities.includes("cyprus_merchant");
    const isCloudTalkUser = data?.viewer?.capabilities.includes(
      "customer_cloud_talk"
    );

    const isCustomerDefault =
      data?.viewer?.capabilities.includes("customer_default");

    const loggedIn = Boolean(user);

    return {
      loggedIn,
      isUnlicensedUser,
      isCustomerDefault,
      isCyprusMerchant,
      isOtherBankUser,
      isSyntechUser,
      isBankUser,
      isCloudTalkUser,
      isDefaultCustomer,
      user,
      loading,
      error,
    };
  }, [data, loading, error]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuth = () => useContext(AuthContext);

export default useAuth;
